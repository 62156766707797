<template>
  <div class="dashboard">
    <modal :show.sync="mostrarHistorico" :if.sync="mostrarHistorico" :title="'Histórico da negativação na OkCred '+historico.razaoSocial">
      <template slot="body">

        <table class="vuetable va-data-table__vuetable va-table va-table--striped va-table--hoverable">
          <thead>
            <th>Data</th>
            <th>Pessoa</th>
            <th>Operação</th>
            <th>Situação</th>
            <th>Erro</th>
          </thead>

          <tbody>
            <tr v-for="(item,index) in historico.cnpj" v-bind:index="index" v-bind:key="index">
              <td>{{new Date(item.data).toLocaleString().substr(0,10)}}</td>
              <td>CNPJ</td>
              <td>{{item.operacao}}</td>
              <td>{{item.situacao}}</td>
              <td>{{item.codigoErro}} - {{item.motivoErro}}</td>
            </tr>

            <tr v-for="(item,index) in historico.avalistas" v-bind:index="index" v-bind:key="index">

              <td>{{new Date(item.data).toLocaleString().substr(0,10)}}</td>
              <td>{{item.nomeDevedor}}</td>
              <td>{{item.operacao}}</td>
              <td>{{item.situacao}}</td>
              <td>{{item.codigoErro}} - {{item.motivoErro}}</td>
            </tr>
          </tbody>
        </table>

      </template>

      <template slot="footer">

      </template>
    </modal>
    <va-card :title="$t('negativacao.statusMensagensInvestidores.title') + ` - ${filteredData.length} operações encontradas`">
      <div class="row align--center mb-1">
        <div class="flex xs12 sm6">
          <va-input
            class="ma-0"
            :value="term"
            :placeholder="$t('tables.searchByName')"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
        <div class="flex xs12 md3 offset--md3">
          <va-select
            :options="perPageOptions"
            v-model="perPage"
            label="Itens por página"
          />
        </div>
      </div>

      <div class="row align--center mb-1">
        <div class="">

          <va-radio-button
            v-model="filtro"
            option="todos"
            label="Todos"
          />
        </div>
      </div>

      <va-data-table
        :per-page="perPage"
        :fields="mode ? detailedFields : fields"
        :data="filteredData"
        :loading="loading"
        hoverable
      >
        <template slot="icon">
          <va-icon name="fa fa-user" color="secondary" />
        </template>
        <template v-slot:starred="props">
          <va-icon
            v-if="props.rowData.starred"
            name="fa fa-star"
            color="warning"
          />
        </template>
        <template v-slot:status="props">
          {{ props.rowData.status }}
        </template>
        <template slot="razaoSocial" slot-scope="props">
          <a :href="`https://zxvf.tutudigital.com.br/tomadores/${props.rowData.tomadorId}/dossie1`" target="_blank">
            {{props.rowData.razaoSocial}}
          </a>
        </template>
        <template slot="ultimoStatus" slot-scope="props">
          <a href="javascript://" v-on:click="abrirHistorico(props.rowData)">
            {{props.rowData.ultimoStatus}}
          </a>
        </template>

      </va-data-table>
    </va-card>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import operacoesService from '../../services/Cobranca/OperacoesService'
import Modal from '../modal/Modal'
export default {
  components: { Modal },
  data () {
    return {
      operacoes: [],
      perPage: 15,
      filtro: 'todos',
      perPageOptions: ['15', '25', '35', '50', '75', '100', '200', '300', '400', '500'],
      loading: false,
      term: null,
      mode: 0,
      mostrarHistorico: false,
      historico: {},
    }
  },
  computed: {
    fields () {
      return [{
        name: 'emprestimoId',
        title: 'EmprestimoId',
      }, {
        name: '__slot:razaoSocial',
        title: 'Razão Social',
      }, {
        name: 'acordoId',
        title: 'AcordoId',
      },
      {
        name: 'status',
        title: 'Status',
      },
      {
        name: 'situacao',
        title: 'Situação',
      },
      {
        name: 'diasEmAtraso',
        title: 'Dias em Atraso',
      },
      {
        name: 'parcelaMaisVencida',
        title: 'Parcela mais vencida',
      },
      {
        name: 'ultimaMsg',
        title: 'Última Msg',
      },
      {
        name: 'Data última msg enviada para Investidores',
        title: 'dataUltimoStatusAtrasoEnviadoInvestidores',
      },
      {
        name: 'diasDesdeUltimoStatusEnviadoAosInvestidores',
        title: 'Dias desde última msg enviada para Investidores',
      }]
    },
    modeOptions () {
      return [{
        value: 0,
        label: this.$t('dashboard.table.brief'),
      }]
    },
    filteredData () {
      let ret = null
      if (!this.term || this.term.length < 1) {
        ret = this.operacoes
      } else {
        ret = this.operacoes.filter(item => {
          return item.razaoSocial.toLowerCase().startsWith(this.term.toLowerCase())
        })
      }

      if (this.filtro === 'apenasCNPJ') {
        ret = ret.filter(x => x.negativado === 'Sim' && x.algumAvalistaNegativado === 'Não')
      } else if (this.filtro === 'todosAvalistas') {
        ret = ret.filter(x => x.todosAvalistasNegativados === 'Sim' && x.negativado === 'Não')
      } else if (this.filtro === 'algunsAvalistas') {
        ret = ret.filter(x => x.algumAvalistaNegativado === 'Sim' && x.negativado === 'Sim')
      } else if (this.filtro === 'cnpjAlgunsAvalistas') {
        ret = ret.filter(x => x.negativado === 'Sim' && x.algumAvalistaNegativado === 'Sim')
      } else if (this.filtro === 'completo') {
        ret = ret.filter(x => x.negativado === 'Sim' && x.todosAvalistasNegativados === 'Sim')
      } else if (this.filtro === 'comErroRetorno') {
        ret = ret.filter(x => x.TemErroAlgumAvalista || x.historicoStatus.filter(x => x.codigoErro).length)
      }

      return ret
    },
  },
  methods: {
    abrirHistorico (item) {
      this.historico.razaoSocial = item.razaoSocial
      this.mostrarHistorico = true
      this.historico.cnpj = item.historicoStatus
      this.historico.avalistas = []
      for (let i = 0; i < item.sociosNegativados.length; i++) {
        for (let j = 0; j < item.sociosNegativados[i].historicoStatus.length; j++) {
          item.sociosNegativados[i].historicoStatus[j].nomeDevedor = item.sociosNegativados[i].nome
          this.historico.avalistas.push(item.sociosNegativados[i].historicoStatus[j])
        }
      }
    },
    getStatusColor (status) {
      if (status === 'paid') {
        return 'success'
      }

      if (status === 'processing') {
        return 'info'
      }

      return 'danger'
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
  async mounted () {
    this.operacoes = (await operacoesService.statusMensagensInadimplencia()).data.data
  },
}
</script>
